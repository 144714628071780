






import Vue from 'vue';

export default Vue.extend({
    props: {
        prices: {
            type: Object,
            required: true,
        },
        showKey: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        isSotozei() {
            return this.prices.exclude != null;
        },
        price() {
            if (this.showKey) {
                return this.prices[this.showKey];
            }

            return this.isSotozei ? this.prices.exclude : this.prices.include;
        },
        text() {
            const price = this.price;
            if (price != null) {
                return price.toLocaleString();
            }

            return '';
        }
    }
})
